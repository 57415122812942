<template>
	<div class="app-body has-bottom">
		<div class="home-container scroll-cont">
			<div class="home-amount-cont" >
				<div class="">
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 机柜二维码</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<input type="text" placeholder="机柜二维码"  v-model="info.imei" class="sel-cell-input" @click="scanSearch" readonly>
							</div>
						</div>
						<div class="sel-cell-icon" @click="filterDevice">
							<img src="../../assets/images/scan.png" alt="" style="width:0.6rem;">
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span>设备名称</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="设备名称"  v-model="info.cabinet_type" class="sel-cell-input" readonly>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span>设备型号</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="设备型号"  v-model="info.device_model" class="sel-cell-input" readonly>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span>地区</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="地区"  v-model="info.country_name" class="sel-cell-input" readonly>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span>报修单号</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="报修单号"  v-model="info.repair_order" class="sel-cell-input" readonly>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span>报修时间</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="报修时间"  v-model="info.repair_time" class="sel-cell-input" readonly>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span>不良描述</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="不良描述"  v-model="info.poor_description" class="sel-cell-input" readonly>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 充电宝数量</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="请输入充电宝数量"  v-model="info.battery_num" class="sel-cell-input" >
								</div>
							</div>
						</div>
						<div class="sel-cell-icon">
							个
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 电源线数量</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="请输入电源线数量"  v-model="info.power_cable_num" class="sel-cell-input" >
								</div>
							</div>
						</div>
						<div class="sel-cell-icon">
							条
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 不良分析</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<input type="text" placeholder="请输入不良分析"  v-model="info.bad_analysis" class="sel-cell-input" >
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 解决方法</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<select class="sel-cell-input " v-model="info.solution" >
										<option value="">请选择解决方法</option>
										<option value="1">误测</option>
										<option value="2">返厂</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 因素分类</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<select class="sel-cell-input " v-model="info.factor_sort" >
										<option value="">请选择因素分类</option>
										<option value="1">人为</option>
										<option value="2">品质</option>
										<option value="3">物料</option>
										<option value="4">其他</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 上传图片</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<div class="sel-cell-field">
									<van-uploader :after-read="onReadDetail" >
									  <van-icon>
										 <div class="upload_icon">
										 	<p class="f18 text-center text-blue">
										 		<strong><i class="van-icon van-icon-plus"></i></strong>
										 	</p>
										 </div>
									  	<img :src="info.status_pic" alt="" class="uploadPic">
									  </van-icon>
									</van-uploader>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="absolute-bottom">
			<button class="btn-blue btn-lg btn-block f16" @click="submitFormData">确定</button>
		</div>
	</div>
</template>
<script>
import wx from 'weixin-js-sdk'
import { wxbase } from "@/api/user.js"
import { Toast,PullRefresh,List,Popup,uploader,icon} from 'vant'
import { scanCabinetTesting , submitEquipmentTestData ,uploadBase64} from '@/api/device'
export default{
	components: {
		[PullRefresh.name]:PullRefresh,
		[List.name]:List,
		[Popup.name]:Popup,
		[uploader.name]:uploader,
		[icon.name]:icon
	},
	data() {
		return {
			info: {},
			imei: ''
		}
	},
	watch: {

	},
	computed: {

    },
	mounted() {
		if(this.$route.query.imei){
			this.imei = this.$route.query.imei
			this.filterDevice()
		}
	},
	methods: {
		godetail(path) {
			this.$router.push(path)
		},
		scanSearch() {
			var self = this
			let url = window.location.href.split('#')[0]
			wxbase(url).then(response => {
				var jsondata = response.data
				wx.config({
					debug: false,
					appId: jsondata.appid,
					timestamp: jsondata.timestamp,
					nonceStr: jsondata.nonceStr,
					signature: jsondata.signature,
					jsApiList: [
						'checkJsApi',
						'scanQRCode'
					]
					// 需要使用的JS接口列表
				});
				wx.ready(function () {
					wx.checkJsApi({
						jsApiList: ['scanQRCode'],
						success: function () {
						}
					});
					wx.scanQRCode({
						needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
						scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
						success: function (res) {
							var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
							if(result.split('=')[1]){
								self.imei = result.split('=')[1]
								self.filterDevice()
							}else{
								Toast('当前不是合格的机柜二维码')
							}

							console.log("扫描结果："+result);
						}
					});
				}); 
				wx.error(function (res) {
					console.log("出错了：" + res.errMsg);
				});
			}).catch(error=>{
				console.log(error);
			});
        },
		onReadDetail(file) {
	      this.onImg(file)
	    },
	    //图片上传
	    onImg(file){
	    	var self = this
	    	//压缩图片
	    	if(/\/(?:jpeg|png)/i.test(file.file.type)&&file.file.size>10485760) {
		      // 创建Canvas对象(画布)
		      let canvas =  document.createElement('canvas')  
		      // 获取对应的CanvasRenderingContext2D对象(画笔)
		      let context = canvas.getContext('2d') 
		      // 创建新的图片对象 
		      let img = new Image()
		      // 指定图片的DataURL(图片的base64编码数据)
		      img.src = file.content
		      // 监听浏览器加载图片完成，然后进行进行绘制
		      img.onload = () => {
		        // 指定canvas画布大小，该大小为最后生成图片的大小
		        let height = img.height / (img.width / 800) 
		        canvas.width = 800
		        canvas.height = height
		        /* drawImage画布绘制的方法。(0,0)表示以Canvas画布左上角为起点，400，300是将图片按给定的像素进行缩小。
		        如果不指定缩小的像素图片将以图片原始大小进行绘制，图片像素如果大于画布将会从左上角开始按画布大小部分绘制图片，最后的图片就是张局部图。*/ 
		        context.drawImage(img, 0, 0, 800, height)
		        // 将绘制完成的图片重新转化为base64编码，file.file.type为图片类型，0.92为默认压缩质量
		        file.content = canvas.toDataURL(file.file.type, 0.8) 
		        // 最后将base64编码的图片保存到数组中，留待上传。
		        self.getImageUpload(file)
		      }
		    }else{
		    	self.getImageUpload(file)
		    } 
	    },
	    getImageUpload(file){
	    	Toast({
			  type:'loading',
			  mask: false,
			  loadingType:'spinner'
			})
			var params = {}
			params.image = file.content
	    	uploadBase64(params).then(response=>{
	    		Toast.clear()
	    		if(response.data.error_code == 1000){
	    			this.info.status_pic = response.data.data
	    		}else{
					Toast(response.data.msg)
	    		}
            }).catch(error=>{
            	console.log(error)
            })
	    },
	    filterDevice() {
	    	let params = {}
	    	params.imei = this.imei
	    	scanCabinetTesting(params).then(res => {
				if(res.data.error_code == 1000){
					this.info = res.data.data
				}else{
					Toast(res.msg)
				}
			}).catch(error=>{
				console.log(error)
			})
	    },
	    submitFormData() {
	    	var validateFlag = this.isValidateForm()
			if(validateFlag == true)
			{
		    	submitEquipmentTestData(this.info).then(res => {
					if(res.data.error_code == 1000){
						Toast(res.data.msg)
						setTimeout(()=>{
							this.$router.push({path:'/DeviceTestList'})
						},1000)
					}else{
						Toast(res.data.msg)
					}
				}).catch(error=>{
					console.log(error)
				})
			}else{
				Toast(validateFlag)
			}
	    },
	    isValidateForm() {
	    	if(this.info.battery_num === ''){
        		return "充电宝数量不能为空"
        	}
        	if(this.info.power_cable_num === ''){
        		return "电源线数量不能为空"
        	}
        	if(this.info.bad_analysis === ''){
        		return "不良分析不能为空"
        	}
        	if(this.info.solution == ''){
        		return "解决方法不能为空"
        	}
        	if(this.info.factor_sort == ''){
        		return "因素分类不能为空"
        	}	
	    	return true
	    }
	}
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/mixin';
.app-body{
	background:#fff;
}
.van-uploader{
	 width: 100%;
	 margin: 1%;
	.van-icon, .van-icon:before{
		    height: 100%;
   			 display: block;
	}
	.uploadPic{
		width:30vw;
		height:30vw;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
	}
}
.upload_icon{
	width:30vw;
	height:30vw;
	background:#f5f5f5;
	border:1px solid #f3f3f3;
	display:table-cell;
	vertical-align:middle;
	text-align:center;
}
.van-icon-cross{
	position:absolute;
	right:2px;top:0px;
	background:#b90c0c;
	border-radius:50%;
	color:#fff;
	padding:2px;
}
</style>